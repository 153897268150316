<template>
  <v-card class="mx-auto" max-width="400">
    <v-toolbar class="backgroundColor">
      <v-spacer></v-spacer>
      <v-toolbar-title id="toolbarTitle"><b>Directions</b></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="white">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" icon @click="closeDirectionsPanel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span style="color:black">{{ $t("Close") }}</span>
      </v-tooltip>
    </v-toolbar>
    <vue-perfect-scrollbar
      class="chat-room--scrollbar"
      v-bind:style="{ 'min-height': '1vh' }"
      ref="vuePerfectScroll"
    >
      <v-list
        v-for="(direction, index) of directions"
        :key="index"
        id="listStyle"
      >
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content id="numberStyling"
            >{{ ++index }}.</v-list-item-content
          >
          <v-list-item-content
            v-html="direction.instruction"
            class="ellipsis"
          ></v-list-item-content>
        </v-list-item>
      </v-list>
    </vue-perfect-scrollbar>
    <v-divider></v-divider>
  </v-card>
</template>
<script>
import { right } from "inquirer/lib/utils/readline";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapGetters } from "vuex";

export default {
  name: "directionsDialogBox",
  computed: {
    ...mapGetters({
      directions: "routing/getRouteDirections"
    })
  },
  components: {
    VuePerfectScrollbar
  },
  props: {
    isDirectionsDialogOpen: {
      type: Boolean
    }
  },
  methods: {
    closeDirectionsPanel() {
      this.$store.commit("routing/changeIsRouteDirectionWindowOpen", false);
    },
    getDirectionIcon(direction) {
      let icon;
      if (direction.action) {
        if (direction.action === "turn") {
          if (direction.direction === "right") {
            return "mdi-arrow-right-top";
          } else {
            return "mdi-arrow-left-top";
          }
        } else if (direction.action === "continue") {
          return "mdi-arrow-up";
        } else {
          return "mdi-arrow-up";
        }
      }
    }
  }
};
</script>
<style>
.text-align-end {
  text-align: justify;
}
#more-padding-on-right {
  padding-right: 10px;
  font-size: 16px;
  font-weight: bolder;
}
.bold_font {
  font-weight: bold;
}
.ps-container {
  height: 350px;
}
#numberStyling {
  max-width: 50px !important;
}
#listStyle {
  padding: 0px;
}
</style>
